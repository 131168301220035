

















































































































import Vue from 'vue';

import Field from '@/components/Field.vue';
import Switcher from '@/components/Switcher.vue';
import PostbackTree from '@/components/Postback/Tree.vue';
import ExternalUsers from '@/components/ExternalUsers.vue';

import RCP from '@/api/RCP';

import swal from 'sweetalert2';

import { ErrorResponse } from '@/types/Errors';

import actions from '@/helpers/const/actions';
import inParams from '@/helpers/const/inParams';
import Validator from '@/helpers/validator';

const actionsModel = {} as any;

Object.keys(actions).forEach((key) => {
    actionsModel[key] = false;
});

export default Vue.extend({
    name: 'Partner',
    data() {
        const inParameters = {} as any;
        const inParametersRules = {} as any;
        const parametersErrors = {} as any;

        inParams.forEach((name: any) => {
            inParameters[name] = '';
            inParametersRules[name] = 'min:1|max:255';
            parametersErrors[name] = '';
        });

        return {
            externalUser: {} as any,
            check: false,
            loaded: false,
            form: {
                project_id: 0,
                code: '',
                additional_code: '',
                title: '',
                description: '',
                cost: null as any,
                archive: false,
                target_events: [],
                post_back_actions: [],
                channel: ''
            } as any,
            formRules: {
                project_id: 'required',
                code: 'required|min:1|max:255',
                additional_code: 'min:1|max:255',
                title: 'required|min:1|max:255',
                cost: 'required'
            },
            errors: {} as any,
            inParameters,
            inParametersRules,
            parametersErrors,
            projects: {} as any,
            active: true,
            actions,
            actionsModel,
            request: false,
            formRequest: false,
            formWatcher: () => { return; },
            inParamsWatcher: () => { return; },
            checkWatcher: () => { return; },
            checkParamsWatcher: () => { return; },
            checkPartner: () => { return; },
            options: {
                channel: {
                    api: 'API',
                    context: 'Контекстная реклама',
                    mailing: 'Рассылки',
                    referral: 'Партнерская сеть',
                    seo: 'SEO',
                    social: 'Соц. сети'
                }
            }
        };
    },
    mounted() {
        this.getFilters().then(this.getPartner);
    },
    computed: {
        projectId(): number {
            return +this.$route.params.projectId;
        },
        partnerId(): number {
            return +this.$route.params.partnerId;
        }
    },
    watch: {
        $route() {
            this.getPartner();
        },
        active(val) {
            this.form.archive = !val;
        },
        formRequest(val) {
            if (val) {
                setTimeout(() => {
                    const element = document.querySelector('.field--error');

                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center'});
                    }
                });

                this.formWatcher = this.$watch('form', () => {
                    this.validate(false);
                }, {
                    deep: true
                });

                this.inParamsWatcher = this.$watch('inParameters', () => {
                    this.validate(false);
                }, {
                    deep: true
                });
            } else {
                this.formWatcher();
                this.inParamsWatcher();
            }
        }
    },
    methods: {
        validate(submit = true) {
            if (!this.request) {
                const validate = new Validator(this.form, this.formRules).make();
                const paramsValidate = new Validator(this.inParameters, this.inParametersRules).make();

                this.errors = validate.errors;
                this.parametersErrors = paramsValidate.errors;

                if (validate.isValid && paramsValidate.isValid && submit) {
                    this.savePartner();
                } else {
                    this.formRequest = true;
                }
            }
        },
        savePartner() {
            this.request = true;
            this.check = false;
            const form = {} as any;

            Object.keys(this.form).forEach((key: any) => {
                const value = this.form[key];

                if (typeof value === 'boolean') {
                    form[key] = value;
                } else {
                    form[key] = value || null;

                    if (key === 'description') {
                        form[key] = value || '';
                    }
                }

                if (key === 'cost') {
                    form[key] *= 1;
                }
            });

            const inParameters = [] as any;

            Object.keys(this.inParameters).forEach((name: any) => {
                if (this.inParameters[name]) {
                    const param = {
                        name,
                        value: this.inParameters[name]
                    } as any;

                    const exist = this.form.in_parameters.find((elem: any) => {
                       return elem.name === name;
                    });

                    if (exist) {
                        param.id = exist.id;
                    }

                    inParameters.push(param);
                }
            });

            form.in_parameters = inParameters;

            form.post_back_actions = this.form.post_back_actions.map(({action, send_type, uri, id, parameters}) => {
                const returned = {
                    action,
                    send_type,
                    uri
                } as any;

                if (id) {
                    returned.id = id;
                }

                let params = [] as any;

                if (parameters.length > 0) {
                    params = parameters.map(({name, default_value, template, id: paramId}) => {
                        const param = {
                            name,
                            default_value: default_value || null,
                            template: template || null
                        } as any;

                        if (paramId) {
                            param.id = paramId;
                        }

                        return param;
                    });
                }

                returned.parameters = params;

                return returned;
            });

            const eventsArr = [] as any;

            Object.keys(this.actionsModel).forEach((key: string) => {
                if (this.actionsModel[key]) {
                    const event = {
                        action: key
                    } as any;

                    const exist = this.form.target_events.find((elem: any) => {
                        return elem.action === key;
                    });

                    if (exist) {
                        event.id = exist.id;
                    }

                    eventsArr.push(event);
                }
            });

            form.target_events = eventsArr;

            RCP({
                method: 'Partner.edit',
                params: form,
                id: 'savePartner'
            }).then((response) => {
                swal.fire({
                    icon: 'success',
                    title: 'Успешно',
                    text: 'Данные успешно обновлены',
                    confirmButtonText: 'Ок',
                    confirmButtonColor: '#605ab9'
                });

                if (this.externalUser && this.externalUser.id && form.id) {
                    RCP({
                        method: 'UserExternalAssoc',
                        id: 'UserExternalAssoc',
                        params: {
                            partner_id: form.id,
                            user_external_id: this.externalUser.id
                        }
                    }).then(() => {
                        this.$router.push('/partners');
                    }).catch((error: ErrorResponse) => {
                        if (error.show) {
                            swal.fire({
                                icon: 'error',
                                title: 'Ошибка',
                                text: error.message,
                                confirmButtonText: 'Ок',
                                confirmButtonColor: '#605ab9'
                            });
                        }

                        this.request = false;
                    });
                } else if (this.form.user_external && this.form.user_external.id) {
                    RCP({
                        method: 'UserExternalDeAssoc',
                        id: 'UserExternalDeAssoc',
                        params: {
                            partner_id: form.id
                        }
                    }).then(() => {
                        this.$router.push('/partners');
                    }).catch((error: ErrorResponse) => {
                        if (error.show) {
                            swal.fire({
                                icon: 'error',
                                title: 'Ошибка',
                                text: error.message,
                                confirmButtonText: 'Ок',
                                confirmButtonColor: '#605ab9'
                            });
                        }

                        this.request = false;
                    });
                } else {
                    this.$router.push('/partners');
                }
            }).catch((error: ErrorResponse) => {
                if (error.show) {
                    swal.fire({
                        icon: 'error',
                        title: 'Ошибка',
                        text: error.message,
                        confirmButtonText: 'Ок',
                        confirmButtonColor: '#605ab9'
                    });
                }

                this.request = false;
            });
        },
        getFilters() {
            return new Promise((resolve) => {

                this.projects = this.$store.state.filters.projects;

                if (this.projects.length > 0) {
                    this.form.project_id = this.projects[0].id;
                }

                resolve();
            });
        },
        getPartner() {
            this.check = false;
            this.loaded = false;
            this.formRequest = false;

            this.checkWatcher();
            this.checkParamsWatcher();
            this.checkPartner();

            const clearObject = (ref: object) => {
                Object.keys(ref).forEach((key: any) => {
                    ref[key] = '';
                });
            };

            clearObject(this.errors);
            clearObject(this.parametersErrors);
            clearObject(this.inParameters);

            RCP({
                method: 'Partner.get',
                params: {
                    project_id: this.projectId,
                    partner_id: this.partnerId
                },
                id: 'getPartner'
            }).then((result: any) => {

                if (result.user_external) {
                    this.externalUser = result.user_external;
                }

                result.in_parameters.forEach((item: any) => {
                    if (item.value) {
                        this.inParameters[item.name] = item.value;
                    }
                });

                Object.keys(result).forEach((key: any) => {
                    if (typeof result[key] === 'boolean') {
                        this.form[key] = result[key];
                    } else {
                        this.form[key] = result[key] || '';
                    }
                });

                const targetEvents = [] as any;

                this.form.target_events.forEach((item: any) => {
                    targetEvents.push(item.action);
                });

                Object.keys(actions).forEach((key) => {
                    this.actionsModel[key] = targetEvents.indexOf(key) !== -1;
                });

                this.active = !this.form.archive;

                this.loaded = true;

                this.checkWatcher = this.$watch('form', () => {
                    this.check = true;
                    this.checkParamsWatcher();
                    this.checkWatcher();
                    this.checkPartner();
                }, {
                    deep: true
                });

                this.checkPartner = this.$watch('externalUser', () => {
                    this.check = true;
                    this.checkParamsWatcher();
                    this.checkWatcher();
                    this.checkPartner();
                }, {
                    deep: true
                });

                this.checkParamsWatcher = this.$watch('inParameters', () => {
                    this.check = true;
                    this.checkParamsWatcher();
                    this.checkWatcher();
                    this.checkPartner();
                }, {
                    deep: true
                });
            }).catch((error: ErrorResponse) => {
                if (error.show) {
                    swal.fire({
                        icon: 'error',
                        title: 'Ошибка',
                        text: error.message,
                        confirmButtonText: 'Ок',
                        confirmButtonColor: '#605ab9'
                    });
                }

                this.$router.push('/partners');
            });
        },
        checkChanges() {
            return new Promise((resolve) => {
                if (this.check) {
                    swal.fire({
                        title: 'Внесены изменения',
                        text: 'Вы уверены что хотите покинуть страницу не сохранив изменения?',
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonColor: '#605ab9',
                        cancelButtonText: 'Перейти',
                        confirmButtonText: 'Остаться'
                    }).then((answer: any) => {
                        if (answer.dismiss === 'cancel') {
                            resolve();
                        }
                    });

                } else {
                    resolve();
                }
            });
        }
    },
    beforeRouteLeave(to, from, next: any) {
        this.checkChanges().then(next);
    },
    components: {
        Field,
        Switcher,
        PostbackTree,
        ExternalUsers
    }
});
